import { Link } from "gatsby"
import * as React from "react"
//import { Link } from "gatsby"

//import Layout from "../components/Layout"
import Seo from "../components/SEO"

const TermosCondicoesPage = () => (
  <>
    <div className="artigos-head">
      <div className="container">
        <span className="title">Termos e Condições</span>
      </div>
    </div>
    <section className="just-text-section">
      <div className="container">
        <p className="subtitle">INFORMAÇÕES GERAIS</p>
        <p>
          O presente <i>website</i> e a informação nele contida são da
          titularidade de Maria Manuel Vieira, Advogada, com cédula profissional
          n.º 66565c e domicílio profissional sito na Rua Dr. Alberto Souto, n.º
          24, 1.º Andar, Sala 2, 3800-148 Aveiro.
        </p>
        <p>
          Este <i>website</i> tem como finalidade o fornecimento de informações
          gerais sobre MMV Advogados e a sua atividade contendo, para o efeito,
          textos, imagens, marcas ou logótipos, material de áudio ou vídeo da
          autoria de Maria Manuel Vieira, exceto informação expressa em
          contrário.
        </p>
        <p>
          Nenhum conteúdo do <i>website</i> implica um vínculo, seja de que
          natureza for, de relações de cariz profissional ou prestação serviços
          jurídicos, valendo o mesmo unicamente como meio de contacto com MMV
          Advogados e os seus colaboradores.
        </p>
        <p>
          Pese embora o melhor esforço por parte da MMV Advogados em manter as
          informações do <i>website</i> atualizadas, os seus Utilizadores não
          devem usar ou divulgar as informações nele contidas sem consultar
          previamente um Advogado.
        </p>
        <p>
          Nestes termos, tanto a MMV Advogados como à sua titular, não assiste
          qualquer responsabilidade, perante quaisquer Utilizadores do{" "}
          <i>website</i>, por quaisquer danos decorrentes da utilização das
          informações nele contidas.
        </p>
        <br />
        <p className="subtitle">
          TERMOS DE UTILIZAÇÃO & TRATAMENTO DE DADOS PESSOAIS
        </p>
        <p>
          Ao utilizar este <i>website</i>, bem como os serviços associados, o
          Utilizador concorda com os presentes Termos de Utilização, assim como
          conhece a Política de Privacidade.
        </p>
        <p>
          A proteção dos seus dados pessoais é importante para a MMV Advogados.
          Para obter mais informações, consulte a nossa{" "}
          <Link to="/politica-privacidade">Política de Privacidade</Link>.
        </p>
      </div>
    </section>
  </>
)

export const Head = () => <Seo title="Termos e condições" />

export default TermosCondicoesPage
